import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import prepareAgeQuery from 'utils/prepareAgeQuery'

export default (
  getAge = (props) => props.patientInfo.age,
  getGender = (props) => props.patientInfo.gender,
  getSymptoms = (props) => props.symptoms,
  key = 'triage',
  covid19
) => (WrappedComponent) =>
  class WithTriage extends Component {
    render() {
      const age = getAge(this.props)
      const sex = getGender(this.props)
      const evidence = getSymptoms(this.props).map((symptom) => {
        const ev = {
          id: symptom.id,
          choice_id: symptom.choiceId,
          initial: symptom.initial,
        }
        if (covid19) {
          delete ev.initial
        }
        return ev
      })

      if (!age || !sex || (evidence.length === 0 && !covid19)) {
        return <WrappedComponent {...this.props} />
      }
      return (
        <Query
          variables={{
            input: {
              age: prepareAgeQuery(age),
              sex,
              evidence,
              extras: { enable_triage_5: true },
            },
          }}
          query={gql`
            query triage {
              triage(input: $input)
                @rest(type: "Triage", path: "triage/", method: "POST") {
                triage_level
                serious @type(name: "TriageSymptom") {
                  id
                  name
                  common_name
                  is_emergency
                }
              }
            }
          `}
          ssr={false}
          context={covid19 ? { headers: { 'covid-19-mode': true } } : undefined}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key],
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
